import React from "react"

const Footer = () => (
  <div className="FooterGroup">
    <div className="Footer">
      <p>
        Kyle A. Wiltshire &copy; {new Date().getFullYear()}
      </p>
    </div>
  </div>
)

export default Footer