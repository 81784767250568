import React from "react"
import { Link } from "react-scroll"
import "./header.css"

const Header = () => (
  <div className="Header">
    <div className="HeaderGroup">
      <Link
        activeClass="active"
        to="about"
        spy={true}
        smooth={true}
        duration={500}
      >
        <a href="#about">About</a>
      </Link>
      <a href="https://www.medium.com/@kyleadrian"
        target="_blank"
        rel="noopener noreferrer"
      >Blog</a>
      <Link
        activeClass="active"
        to="projects"
        spy={true}
        smooth={true}
        duration={500}
      >
        <a href="#about">Projects</a>
      </Link>
    </div>
  </div>
)

export default Header
